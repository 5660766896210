import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { cookieConfirmDialog } from './components/cookie-dialog/cookie-dialog.component';
import { CookieConfirmService } from './services/cookie-confirm.service';
import { LangSelectService } from './services/lang-select.service';
import { LoginService } from './services/login.service';
import { isPlatformBrowser } from '@angular/common';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  noUser = this.logInService.noUser$;
  userClient = this.logInService.clientLoggedIn$;
  userCompany = this.logInService.companyLoggedIn$;
  userAdmin = this.logInService.adminLoggedIn$;
  registeringUser = this.logInService.registering$;

  constructor(
    private langSelectService: LangSelectService,
    private dialog: MatDialog,
    private cookieConfirmService: CookieConfirmService,
    private logInService: LoginService,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    private gtmService: GoogleTagManagerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.langSelectService.setInitalLanguage();
    if (isPlatformBrowser(this.platformId)) {
      if (this.cookieConfirmService.cookiesAccepted()) {
        this.langSelectService.setInitalLanguage();
        this.cookieConfirmService.cookiesFinished();
      } else {
        cookieConfirmDialog(this.dialog).subscribe(() => {
          this.cookieConfirmService.cookiesFinished();
        });
        this.langSelectService.setInitalLanguage();
      }

      this.router.events.forEach((item) => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: item.url,
          };
          this.gtmService.pushTag(gtmTag);
        }
      });
    }
    // let userRole = '';

    // if (localStorage.getItem('userRole')) {
    //   userRole = localStorage.getItem('userRole') as string;
    //   if (userRole === 'Candidate') {
    //     this.logInService.clientLogIn();
    //   }
    //   if (userRole === 'Company') {
    //     this.logInService.companyLogIn();
    //   }
    //   if (userRole === 'Admin') {
    //     this.logInService.adminLogIn();
    //   }
    // }

    // openLoginDialog(this.dialog);
    // singnUpDialog(this.dialog);
    // openResetPassDialog(this.dialog);
  }
}
