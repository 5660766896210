import { CUSTOM_ELEMENTS_SCHEMA, NgModule, TransferState } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { MatSelectModule } from '@angular/material/select';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { register } from 'swiper/element/bundle';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LangSelectComponent } from './header/lang-select/lang-select.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { LangInterceptor } from './interceptors/lang.interceptor';
import { translateBrowserLoaderFactory } from './loaders/translate-browser.loader';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { environment } from 'src/environments/environment';
import { AdsenseModule } from 'ng2-adsense';

register();

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LangSelectComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDialogModule,
    HttpClientModule,
    GoogleTagManagerModule.forRoot({
      id: environment.APP_GOOGLE_TAG_ID,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
      defaultLanguage: 'en',
    }),
    AdsenseModule.forRoot({
      adClient: 'ca-pub-1591042397557693',
      adSlot: 9418279650,
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    SsrCookieService,
    { provide: HTTP_INTERCEPTORS, useClass: LangInterceptor, multi: true },
    provideClientHydration(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
