<header class="bg-white">
  <div class="container px-3 bg-white">
    <div class="flex items-center relative justify-between">
      <div class="flex py-9 flex-shrink-1 z-30">
        <a
          class="max-w-[140px] sm:max-w-none xl:max-w-[170px] 2xl:max-w-none"
          routerLink="/"
        >
          <svg
            class="max-w-full h-auto"
            width="240"
            height="53"
            viewBox="0 0 240 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.3208 10.9731V42.4526C14.3208 48.8535 11.5407 53 5.7178 53C3.6828 52.9967 1.69394 52.3935 0 51.2657L2.30709 46.2837C3.26179 46.7822 4.3256 47.035 5.40248 47.0194C7.50461 46.9143 7.97234 45.2852 7.97234 42.4578V10.9731H14.3208ZM7.39425 3.73654C7.39425 8.71859 14.9462 8.71859 14.9462 3.73654C14.9462 -1.24551 7.39425 -1.24551 7.39425 3.73654Z"
              fill="#005AAA"
            />
            <path
              d="M130.364 24.0379C130.364 31.49 125.277 37.5231 116.879 37.5231C108.481 37.5231 103.446 31.49 103.446 24.0379C103.446 16.5859 108.586 10.5527 116.826 10.5527C125.066 10.5527 130.358 16.6384 130.358 24.0379H130.364ZM109.868 24.0379C109.868 27.9742 112.228 31.6476 116.9 31.6476C121.572 31.6476 123.931 27.9689 123.931 24.0379C123.931 20.1069 121.204 16.3809 116.9 16.3809C112.27 16.3809 109.836 20.1595 109.836 24.0379H109.868Z"
              fill="#005AAA"
            />
            <path
              d="M139.55 0.273254V14.594C141.069 11.9191 145.331 10.4476 148.048 10.4476C155.605 10.4476 161.218 15.0617 161.218 23.9853C161.218 32.9088 155.5 37.523 147.89 37.523C144.737 37.523 141.542 36.472 139.55 33.3766L139.129 36.9449H133.149V0.273254H139.55ZM139.97 23.9853C139.97 28.652 143.434 31.595 147.475 31.595C151.516 31.595 154.832 28.4996 154.832 23.9853C154.832 19.471 151.579 16.4281 147.475 16.4281C146.479 16.4078 145.49 16.5899 144.567 16.9631C143.644 17.3364 142.806 17.8932 142.104 18.5997C141.403 19.3063 140.852 20.1479 140.485 21.0736C140.118 21.9994 139.943 22.9899 139.97 23.9853Z"
              fill="#005AAA"
            />
            <path
              d="M184.877 11.0782V14.1211L172.969 30.6491H184.988V36.9555H163.21V34.27L175.591 16.9274H164.156V11.0835H184.877V11.0782Z"
              fill="#005AAA"
            />
            <path
              d="M208.857 11.0782V14.1211L196.949 30.6491H208.962V36.9555H187.189V34.27L199.571 16.9274H188.141V11.0835H208.862L208.857 11.0782Z"
              fill="#005AAA"
            />
            <path
              d="M87.0966 38.0065H31.9892C28.3932 38.0065 24.9444 36.578 22.4017 34.0353C19.8589 31.4925 18.4304 28.0438 18.4304 24.4478C18.4304 20.8518 19.8589 17.4031 22.4017 14.8603C24.9444 12.3175 28.3932 10.889 31.9892 10.889H87.0966C90.6926 10.889 94.1413 12.3175 96.684 14.8603C99.2268 17.4031 100.655 20.8518 100.655 24.4478C100.655 28.0438 99.2268 31.4925 96.684 34.0353C94.1413 36.578 90.6926 38.0065 87.0966 38.0065ZM31.9892 16.6962C30.9539 16.6692 29.9237 16.8499 28.9593 17.2274C27.995 17.605 27.116 18.1718 26.3742 18.8946C25.6324 19.6173 25.0429 20.4812 24.6403 21.4354C24.2378 22.3896 24.0304 23.4148 24.0304 24.4504C24.0304 25.4861 24.2378 26.5112 24.6403 27.4654C25.0429 28.4196 25.6324 29.2836 26.3742 30.0063C27.116 30.729 27.995 31.2958 28.9593 31.6734C29.9237 32.051 30.9539 32.2316 31.9892 32.2047H87.0966C89.1182 32.152 91.0393 31.312 92.4505 29.8635C93.8618 28.415 94.6515 26.4727 94.6515 24.4504C94.6515 22.4281 93.8618 20.4858 92.4505 19.0373C91.0393 17.5889 89.1182 16.7488 87.0966 16.6962H31.9892Z"
              fill="#005AAA"
            />
            <path
              d="M39.8357 29.0252L37.7809 26.9703C38.5506 25.8301 38.8751 24.4472 38.6931 23.0836C38.511 21.7201 37.835 20.4707 36.7932 19.5724C35.7513 18.6741 34.4161 18.1892 33.0406 18.2098C31.6651 18.2304 30.3449 18.7549 29.3304 19.684C28.3159 20.613 27.6775 21.882 27.5363 23.2504C27.3951 24.6188 27.7608 25.9914 28.5642 27.1081C29.3677 28.2248 30.5529 29.0079 31.8952 29.3089C33.2375 29.6099 34.6436 29.408 35.8469 28.7414L37.9806 30.875C38.2273 31.1217 38.5619 31.2603 38.9108 31.2603C39.2597 31.2603 39.5943 31.1217 39.841 30.875C40.0877 30.6283 40.2263 30.2937 40.2263 29.9448C40.2263 29.596 40.0877 29.2614 39.841 29.0147L39.8357 29.0252ZM30.1554 23.8487C30.1513 23.2586 30.3227 22.6806 30.6479 22.1883C30.9732 21.6959 31.4375 21.3114 31.9819 21.0836C32.5263 20.8559 33.1261 20.7952 33.705 20.9093C34.284 21.0234 34.8159 21.3072 35.2332 21.7244C35.6504 22.1417 35.9341 22.6736 36.0482 23.2526C36.1623 23.8315 36.1017 24.4313 35.8739 24.9757C35.6462 25.5201 35.2617 25.9844 34.7693 26.3096C34.277 26.6349 33.699 26.8063 33.1089 26.8022C32.3277 26.7953 31.5805 26.4819 31.0281 25.9295C30.4756 25.3771 30.1623 24.6299 30.1554 23.8487Z"
              fill="#5BB3E4"
            />
            <rect
              x="215"
              width="25"
              height="25"
              rx="12.5"
              fill="url(#paint0_linear_169_190)"
            />
            <path
              d="M225.41 17L223.421 13.529H222.914V17H220.691V7.809H224.487C225.215 7.809 225.83 7.93467 226.333 8.186C226.836 8.43733 227.213 8.784 227.464 9.226C227.715 9.65933 227.841 10.1577 227.841 10.721C227.841 11.3883 227.655 11.956 227.282 12.424C226.918 12.892 226.385 13.2127 225.683 13.386L227.867 17H225.41ZM222.914 12.008H224.292C224.734 12.008 225.059 11.9127 225.267 11.722C225.475 11.5227 225.579 11.2323 225.579 10.851C225.579 10.487 225.471 10.201 225.254 9.993C225.046 9.785 224.725 9.681 224.292 9.681H222.914V12.008ZM235.56 14.413C235.56 14.8983 235.434 15.3447 235.183 15.752C234.94 16.1593 234.576 16.4843 234.091 16.727C233.614 16.9697 233.033 17.091 232.349 17.091C231.317 17.091 230.468 16.8397 229.801 16.337C229.133 15.8343 228.769 15.1323 228.709 14.231H231.075C231.109 14.5777 231.231 14.8507 231.439 15.05C231.655 15.2493 231.928 15.349 232.258 15.349C232.544 15.349 232.769 15.271 232.934 15.115C233.098 14.959 233.181 14.751 233.181 14.491C233.181 14.257 233.103 14.062 232.947 13.906C232.799 13.75 232.613 13.6243 232.388 13.529C232.162 13.425 231.85 13.3037 231.452 13.165C230.871 12.9657 230.394 12.775 230.022 12.593C229.658 12.4023 229.341 12.125 229.073 11.761C228.813 11.3883 228.683 10.9073 228.683 10.318C228.683 9.772 228.821 9.29967 229.099 8.901C229.376 8.50233 229.757 8.199 230.243 7.991C230.737 7.77433 231.3 7.666 231.933 7.666C232.955 7.666 233.766 7.90867 234.364 8.394C234.97 8.87933 235.313 9.54667 235.391 10.396H232.986C232.942 10.0927 232.83 9.85433 232.648 9.681C232.474 9.499 232.236 9.408 231.933 9.408C231.673 9.408 231.46 9.47733 231.296 9.616C231.14 9.75467 231.062 9.95833 231.062 10.227C231.062 10.4437 231.131 10.63 231.27 10.786C231.417 10.9333 231.599 11.0547 231.816 11.15C232.032 11.2453 232.344 11.3667 232.752 11.514C233.341 11.7133 233.822 11.9127 234.195 12.112C234.567 12.3027 234.888 12.5843 235.157 12.957C235.425 13.3297 235.56 13.815 235.56 14.413Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear_169_190"
                x1="217.315"
                y1="6.59722"
                x2="239.853"
                y2="8.01572"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.255208" stop-color="#9B59B6" />
                <stop offset="1" stop-color="#005AAA" />
              </linearGradient>
            </defs>
          </svg>
        </a>
      </div>
      <nav
        class="fixed z-20 overflow-auto inset-0 min-h-screen min-w-full xl:min-h-full xl:min-w-min px-3 xl:px-0 xl:static flex flex-col xl:flex-row text-center items-center xl:justify-between xl:flex-1 transition-transform xl:translate-x-0 glassmorph gap-y-9 xl:gap-y-0"
        [ngClass]="isMenuShown"
      >
        <ul
          class="flex-col mt-24 xl:mt-0 xl:flex-row flex gap-y-3 xl:gap-y-0 xl:gap-x-4 2xl:gap-x-6 text-gray font-medium text-2xl xl:text-lg xl:mx-auto relative after:absolute after:w-[310px] after:h-px after:bg-gray-light after:-bottom-5 after:left-1/2 after:-translate-x-1/2 xl:after:hidden"
        >
          <li class="hover:text-blue transition-colors">
            <a
              routerLinkActive="active"
              [routerLink]="['/']"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="closeMenu()"
            >
              {{ 'menu.menu1' | translate }}
            </a>
          </li>
          <li class="hover:text-blue transition-colors">
            <a
              routerLinkActive="active"
              [routerLink]="['menu.candidatesSlug' | translate]"
              (click)="closeMenu()"
              >{{ 'menu.menu2' | translate }}</a
            >
          </li>
          <li class="hover:text-blue transition-colors">
            <a
              routerLinkActive="active"
              [routerLink]="['menu.companySlug' | translate]"
              (click)="closeMenu()"
              >{{ 'menu.menu3' | translate }}</a
            >
          </li>
          <li class="hover:text-blue transition-colors">
            <a
              routerLinkActive="active"
              [routerLink]="['menu.appSlug' | translate]"
              (click)="closeMenu()"
              >{{ 'menu.menu4' | translate }}</a
            >
          </li>
          <!-- <li class="hover:text-blue transition-colors">
            <a
              routerLinkActive="active"
              [routerLink]="['menu.contactSlug' | translate]"
              (click)="closeMenu()"
              >{{ 'menu.menu5' | translate }}</a
            >
          </li> -->
          <li class="hover:text-blue transition-colors">
            <a
              routerLinkActive="active"
              [routerLink]="['menu.blogSlug' | translate]"
              (click)="closeMenu()"
              >{{ 'menu.blog' | translate }}</a
            >
          </li>
          <li class="hover:text-blue transition-colors">
            <a
              routerLinkActive="active"
              [routerLink]="['menu.companyListSlug' | translate]"
              (click)="closeMenu()"
              >{{ 'menu.companies' | translate }}</a
            >
          </li>
        </ul>
        <div class="flex flex-col xl:flex-row items-center gap-9 xl:gap-4">
          <a
            class="text-lg font-bold text-gray"
            href="{{ dashboardLoginUrl + '?lang=' + activeLang }}"
            target="_blank"
          >
            {{ 'menu.login' | translate }}
          </a>
          <a
            class="btn-signup text-white text-lg px-5 py-3.5 rounded-[50px] font-bold"
            href="{{
              dashboardLoginUrl + '?lang=' + activeLang + '&onSignup=1'
            }}"
            target="_blank"
          >
            {{ 'menu.signup' | translate }}
          </a>
        </div>
      </nav>
      <div class="flex gap-4 items-center">
        <app-lang-select class="z-30"></app-lang-select>
        <button class="block xl:hidden z-30" (click)="onMenuClicked()">
          <svg
            width="26"
            height="18"
            viewBox="0 0 26 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0V3.6H25.2V0H0ZM0 7.2V10.8H25.2V7.2H0ZM0 14.4V18H25.2V14.4H0Z"
              fill="#005AAA"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</header>
