import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'about-us',
    loadChildren: () =>
      import('./pages/about-us/about-us.module').then((m) => m.AboutUsModule),
  },
  {
    path: 'o-nama',
    loadChildren: () =>
      import('./pages/about-us/about-us.module').then((m) => m.AboutUsModule),
  },
  {
    path: 'user-agreement',
    loadChildren: () =>
      import('./pages/user-agreement/user-agreement.module').then(
        (m) => m.UserAgreementModule
      ),
  },
  {
    path: 'user-agreement',
    loadChildren: () =>
      import('./pages/user-agreement/user-agreement.module').then(
        (m) => m.UserAgreementModule
      ),
  },
  {
    path: 'refund-policy',
    loadChildren: () =>
      import('./pages/refund-policy/refund-policy.module').then(
        (m) => m.RefundPolicyModule
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./pages/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: 'kontakt',
    loadChildren: () =>
      import('./pages/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./pages/contact/contact.module').then((m) => m.ContactModule),
  },

  {
    path: 'impressum',
    loadChildren: () =>
      import('./pages/impressum/impressum.module').then(
        (m) => m.ImpressumModule
      ),
  },
  {
    path: 'uslovi-koristenja',
    loadChildren: () =>
      import('./pages/tearms-of-use/tearms-of-use.module').then(
        (m) => m.TearmsOfUseModule
      ),
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('./pages/blog-list/blog-list.module').then(
        (m) => m.BlogListModule
      ),
  },
  {
    path: 'kandidati',
    loadChildren: () =>
      import(
        './pages/candidate-presentation/candidate-presentation.module'
      ).then((m) => m.CandidatePresentationModule),
  },
  {
    path: 'candidates',
    loadChildren: () =>
      import(
        './pages/candidate-presentation/candidate-presentation.module'
      ).then((m) => m.CandidatePresentationModule),
  },
  {
    path: 'poslodavci',
    loadChildren: () =>
      import('./pages/company-presentation/company-presentation.module').then(
        (m) => m.CompanyPresentationModule
      ),
  },
  {
    path: 'companies',
    loadChildren: () =>
      import('./pages/company-presentation/company-presentation.module').then(
        (m) => m.CompanyPresentationModule
      ),
  },
  {
    path: 'aplikacija',
    loadChildren: () =>
      import('./pages/app-presentation/app-presentation.module').then(
        (m) => m.AppPresentationModule
      ),
  },
  {
    path: 'application',
    loadChildren: () =>
      import('./pages/app-presentation/app-presentation.module').then(
        (m) => m.AppPresentationModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },

  {
    path: 'istaknuti-poslodavci',
    loadChildren: () =>
      import('./pages/employers-list/employers-list.module').then(
        (m) => m.EmployersListModule
      ),
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./pages/faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'featured-companies',
    loadChildren: () =>
      import('./pages/employers-list/employers-list.module').then(
        (m) => m.EmployersListModule
      ),
  },

  {
    path: 'blog/:postId',
    loadChildren: () =>
      import('./pages/blog-single/blog-single.module').then(
        (m) => m.BlogSingleModule
      ),
  },
  {
    path: 'istaknuti-poslodavci/:employerId',
    loadChildren: () =>
      import('./pages/employers-single/employers-single.module').then(
        (m) => m.EmployersSingleModule
      ),
  },
  {
    path: 'featured-companies/:employerId',
    loadChildren: () =>
      import('./pages/employers-single/employers-single.module').then(
        (m) => m.EmployersSingleModule
      ),
  },
  {
    path: 'blog/tag/:tagId',
    loadChildren: () =>
      import('./pages/blog-tag-list/blog-tag-list.module').then(
        (m) => m.BlogTagListModule
      ),
  },

  {
    path: '**',
    loadChildren: () =>
      import('./pages/error404/error404.module').then((m) => m.Error404Module),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
