import { CookieConfirmService } from './../../services/cookie-confirm.service';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-cookie-dialog',
  templateUrl: './cookie-dialog.component.html',
  styleUrls: ['./cookie-dialog.component.scss'],
})
export class CookieDialogComponent {
  constructor(
    private cookieConfirmService: CookieConfirmService,
    private dialogRef: MatDialogRef<CookieDialogComponent>
  ) {}
  acceptCookies() {
    this.cookieConfirmService.acceptCookies();
    this.dialogRef.close();
  }
}

export function cookieConfirmDialog(dialog: MatDialog) {
  const config = new MatDialogConfig();
  config.disableClose = true;
  config.autoFocus = true;
  config.maxWidth = 788;
  config.maxHeight = 566;
  config.backdropClass = 'customDialogBackdrop';

  config.panelClass = 'customDialogOverlay';

  const dialogRef = dialog.open(CookieDialogComponent, config);

  return dialogRef.afterClosed();
}
